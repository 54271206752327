import React, { useState } from 'react';
import ReactPlayer from 'react-player'
import SvgPlay from './icons/SvgPlay';
import { set } from 'firebase/database';

const PostImages = ({
                        imageURLs,
                        videos,
                        noExpand,
                        openImageLightbox,
                        creator,
                        creator_name,
                        zeroBR,
                        inFeed,
                        navigateToPost
                    }) => {
    const handleImageClick = (event, imageURLs, index, creator, creator_name) => {
        event.stopPropagation();
        if (!noExpand) {
            openImageLightbox(imageURLs, index, creator, creator_name);
        }
    };

    return (
        <div className='w-full'>
            {imageURLs.length === 1 &&
                <div className="flex flex-1">
                    {imageURLs.map((imageURL) => (
                        <div

                            className="flex flex-1"
                            key={imageURL}
                            onClick={(e) => handleImageClick(e, imageURLs, 0, creator, creator_name)}>
                            <img src={imageURL}
                                 alt="Post"
                                 className={`flex-1 object-cover bg-slate-50 w-full max-h-[360px] ${zeroBR ? 'rounded-none' : 'rounded-lg'} border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                 loading="lazy"
                            />
                        </div>
                    ))}
                </div>
            }

            {imageURLs.length === 2 &&
                <div className="flex flex-1">
                    {imageURLs.map((imageURL, i) => (
                        <div className="flex-1"
                             key={imageURL}
                             onClick={(e) => handleImageClick(e, imageURLs, i, creator, creator_name)}>
                            <img src={imageURL}
                                 alt="Post"
                                 className={`flex-1 object-cover bg-slate-50 aspect-[1] max-h-[360px]  ${zeroBR ? 'rounded-none' : (i === 0 ? 'rounded-l-lg' : 'rounded-r-lg')} border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                 loading="lazy"
                            />
                        </div>
                    ))}
                </div>
            }

            {imageURLs.length === 3 &&
                <div className="flex flex-1 flex-col">
                    <div className="flex-1 flex flex-row items-between">
                        {imageURLs.slice(0, 2).map((imageURL, i) => (
                            <div className="flex-1 flex flex-col"
                                 key={imageURL}
                                 onClick={(e) => handleImageClick(e, imageURLs, i, creator, creator_name)}>
                                <img src={imageURL}
                                     alt="Post"
                                     className={`flex-1 object-cover bg-slate-50 max-h-[360px]  ${zeroBR ? 'rounded-none' : (i === 0 ? 'rounded-tl-lg' : 'rounded-tr-lg')} border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                     loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex-1">
                        {imageURLs.slice(2).map((imageURL) => (
                            <div className="flex-1 flex flex-col"
                                 key={imageURL}
                                 onClick={(e) => handleImageClick(e, imageURLs, 2, creator, creator_name)}>
                                <img src={imageURL}
                                     alt="Post"
                                     className={`flex-1 object-cover bg-slate-50 max-h-[360px] rounded-b-lg border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                     loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            }

            {imageURLs.length >= 4 &&
                <div className="flex flex-1 flex-col">
                    <div className="flex-1 flex flex-row">
                        {imageURLs.slice(0, 2).map((imageURL, i) => (
                            <div className="flex-1"
                                 key={imageURL}
                                 onClick={(e) => handleImageClick(e, imageURLs, i, creator, creator_name)}>
                                <img src={imageURL}
                                     alt="Post"
                                     className={`flex-1 object-cover bg-slate-50 max-h-[360px]  aspect-[1.2] ${zeroBR ? 'rounded-none' : (i === 0 ? 'rounded-tl-lg' : 'rounded-tr-lg')} border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                     loading="lazy"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex-1 relative flex flex-row">
                        {imageURLs.slice(2, 4).map((imageURL, i) => (
                            <div className="flex-1"
                                 key={imageURL}
                                 onClick={(e) => handleImageClick(e, imageURLs, i + 2, creator, creator_name)}>
                                <img src={imageURL}
                                     alt="Post"
                                     className={`flex-1 object-cover bg-slate-50 max-h-[360px]  aspect-[1.2] ${zeroBR ? 'rounded-none' : (i === 0 ? 'rounded-bl-lg' : 'rounded-br-lg')} border border-slate-200 hover:border-slate-400 cursor-pointer`}
                                     loading="lazy"
                                />
                            </div>
                        ))}
                        {imageURLs.length > 4 &&
                            <div
                                onClick={(e) => handleImageClick(e, imageURLs, 4, creator, creator_name)}
                                className="absolute bottom-4 right-2 bg-white-alpha-20 max-h-[360px]  p-4 rounded-lg mr-4">
                                <span className="text-white font-bold">+{imageURLs.length - 4} more</span>
                            </div>
                        }
                    </div>
                </div>
            }

            {videos?.length > 0 ?
                videos.map(videoURL =>
                    <div className="relative flex rounded-lg w-full overflow-hidden" key={videoURL}>
                        <ReactPlayer
                            url={videoURL}
                            className={`shadow video flex-1 object-cover w-full bg-slate-50 rounded-lg border border-slate-200 cursor-pointer ${!inFeed && 'h-[50vh]'}`}
                            playing={true}
                            controls={!inFeed}
                            loop={true}
                            muted={inFeed}
                        />
                        {inFeed && false &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                                className='bg-white border-blue-500 flex flex-col items-center justify-center pl-1.5 w-[60px] aspect-square rounded-full'
                            >
                                <SvgPlay
                                    size={32}
                                    className="text-blue-500"
                                />
                            </div>
                        }
                    </div>
                )
                :
                <div/>
            }
        </div>
    );
};

export default PostImages;
