import React, {PureComponent} from 'react';
import {Button} from "./atoms/Button";
import TextInput from "../components/inputs/TextInput";
import Spinner from "../components/Spinner";
import {firebaseAnalytics, firebaseAuth} from "../api/firebase";
import {signUp, signInWithGoogle} from "../api/authentication";
import {signInWithEmailAndPassword} from "firebase/auth";
import {hasLowercaseCharacter, hasUppercaseCharacter, hasDigit, validateEmail} from "../utils/strings";
import SvgRewindClock from "./icons/SvgRewindClock";
import {wait} from "@testing-library/user-event/dist/utils";
import NPOPicture from "./atoms/NPOPicture";
import { getNonProfitPublic } from '../api/nonProfits';
import SvgChevronDown from "./icons/SvgChevronDown";

const np = {
    "address1": "520 8th Ave Fl 7",
    "admins": {},
    "affiliation": "3",
    "category": "Animal Protection and Welfare",
    "change_id": "n_pxURuxrT27JhXuYL4zkkN1pl",
    "city": "New York City",
    "code": "B",
    "contact_email": "luke.franklin@aspca.org",
    "contact_name": "Mr. Luke Franklin",
    "contact_title": "VP, Interactive Marketing & Member Communications",
    "cover_photo": "https://firebasestorage.googleapis.com:443/v0/b/starfish-seethegood.appspot.com/o/non_profits%2Fcover_photo%2F13-1623829?alt=media&token=3eb8e1ad-ffb6-49f1-8f45-10426c9e7053",
    "ein": "13-1623829",
    "financials": {
        "fiscal_year": 2020,
        "form_type": "990",
        "total_assets": 470124444,
        "total_expenses": 267756291,
        "total_revenue": 324772105
    },
    "id": "13-1623829",
    "interested": [],
    "logo_url": "https://storage.googleapis.com/starfish-seethegood.appspot.com/non_profits/profile_picture/13-1623829%283%29?Expires=33134745600&GoogleAccessId=firebase-adminsdk-pqols%40starfish-seethegood.iam.gserviceaccount.com&Signature=JPzHPj8%2FXOTVq3tFztQTn0pyT3nq5Hm2ADTWSgNP8KEpCYq39pxdUiWhZBETp1dD%2BYA4ASzLHKx3QcBhCo2OOfH9WbyeXzN8y124SYvA9fWDfKqdR4qyIMUmMVdm61i9YW5Drx0QuRJx%2F90FldBl5DoTmxXuyWx3AP8vDqDT6Oy9V8oS2S0kBJRAfbEXfLPC4dPa4Bb72mv18fC4SORSjTOqpVGHqDbmJ3H%2FtwAOelhAZ2ixkrpmOposHTuh8CWeq20PxhJ0cTjOhVLxeibAR3zcwPpEUYYGwFaDZMZCdNMgX3Hn2Z9peMTTBe1CA60PUx1aRv89OAtl%2FE%2BR1C5X2w%3D%3D",
    "mission": "The mission of the ASPCA, as stated by our founder, Henry Bergh, is to provide effective means for the prevention of cruelty to animals throughout the United States.",
    "name": "ASPCA",
    "num_employees": "1252",
    "queried": true,
    "ruling_year": 1921,
    "state": "NY",
    "website_url": "http://www.aspca.org",
    "zip": "10018"
}

class SignUpCard extends PureComponent {
    state = {
        username: "",
        email: "",
        password: "",
        error: "",
        loading: false,
        firstName: "",
        lastName: "",
        np: np
    };

    getNpInfo = async () => {
        if (!this.props.ein) return;

        const res = await getNonProfitPublic(this.props.ein);
        this.setState({
            np: res.non_profit,
        });
    }

    componentDidMount = () => {
        this.getNpInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.ein !== prevProps.ein) {
            this.getNpInfo();
        }
    }

    onChangeUsername = (username) => {
        const strWOSpaces = username.replace(/\s+/g, '');
        const finalStr = strWOSpaces.replace(/\W/g, '');
        this.setState({username: finalStr.toLowerCase(), error: ""})
    }

    onChangeEmail = (email) => {
        this.setState({email: email.toLowerCase(), error: ""})
    }

    passwordChanged = e => this.setState({password: e.target.value, error: ""});

    onChangeFirstName = (firstName) => {
        this.setState({firstName, error: ""})
    }

    onChangeLastName = (lastName) => {
        this.setState({lastName, error: ""})
    }

    buttonPressed = () => {
        this.signUpPressed();
    };

    signUpPressed = async () => {
        let error;
        const passLength = this.state.password.length >= 6;
        const passLowercase = hasLowercaseCharacter(this.state.password);
        const passUppercase = hasUppercaseCharacter(this.state.password);
        const passDigit = hasDigit(this.state.password);

        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!this.state.password) {
            error = "Please enter a password"
        }
            // else if (!this.state.firstName || !this.state.lastName) {
            //     error = "Please enter your first and last name"
            // } else if (!this.state.username) {
            //     error = "Please enter a username"
        // }
        else if (!validateEmail(this.state.email)) {
            error = "Please enter a valid email address."
        } else if (!passLength) {
            error = "Password must be at least 6 characters long."
        } else if (!passUppercase) {
            error = "Password must have at least one uppercase letter."
        } else if (!passLowercase) {
            error = "Password must have at least one lowercase letter."
        } else if (!passDigit) {
            error = "Password must have at least one number."
        }

        if (error) {
            this.setState({error})
            return;
        }
        this.setState({processing: true})

        await signUp(this.state.email, this.state.username, this.state.password, this.state.firstName, this.state.lastName).catch(err => {
            this.setState({error: err.response.data.error, processing: false});
            return;
        })
        window.fbq('track', 'CompleteRegistration');
        await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
        this.props.history.replace("/onboarding/" + this.props.path);
        firebaseAnalytics.logEvent('web_sign_up_completed', {})
    };

    signUpGoog = async () => {
        await signInWithGoogle();
        window.fbq('track', 'CompleteRegistration');
        wait(1000).then(this.props.history.replace("/onboarding/" + this.props.path));


    }

    render() {
        const np = this.state.np;
        const branded = true;
        return (
            <div className='flex flex-col items-center md:flex-row md:items-stretch md:gap-6'>
                {!!branded &&
                    <div
                        className='flex flex-col p-4 md:p-4 mt-10 md:mt-0 bg-white border border-slate-200 rounded-xl shadow-xl mb-5 w-[95vw] md:w-[40vw] max-w-[380px]'>
                        <div className='flex flex-row md:flex-col'>
                            <div>
                                <div className='flex flex-row items-center gap-1'>
                                    <SvgRewindClock className={'text-orange-400 w-4 h-4 md:w-5 md:h-5'}/>
                                    <p className='text-orange-400 text-xs md:text-base font-semibold'>
                                        Time sensitive
                                    </p>
                                </div>
                                <p className='text-xl md:text-2xl font-semibold mb-2 text-blue-500'>
                                    Sign up for free and we'll donate on your behalf!
                                </p>
                                <p className='text-sm md:text-base mb-2'>
                                    You're just one step away from unlocking a donation to <span
                                    className='text-orange-400 font-semibold'>{np.name}</span>, on us!
                                </p>
                            </div>
                            <img src={require('../img/brand/Starfish-we-donate (1).jpg')}
                                 className='h-40 md:h-60 border border-slate-200 rounded-xl mx-auto aspect-square object-contain mb-2'/>
                        </div>
                        <div
                            className={`my-2 mb-5 border border-slate-200 p-2 rounded-xl flex flex-row items-center`}
                            key={np.ein}
                        >
                            <NPOPicture
                                size={48}
                                np={np}
                                logoUrl={np.logo_url}
                                className={'border  border-slate-200'}
                            />
                            <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                <p className='text-lg text-slate-800 font-semibold'>
                                    {np.name}
                                </p>
                                <p className='text-xs md:text-sm line-clamp-1 text-slate-500'>
                                    {np.category}
                                </p>
                                {/* <div className='row-ac'>
                                    <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                    <p className='text-xs md:text-sm text-blue-500 ml-4'>
                                        {np.city}, {np.state}
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        {/* <p className='text-sm md:text-base'>
                            By signing up now, we'll donate on your behalf and you'll earn a <span
                            className='text-orange-400 font-semibold'>Donator badge</span> on your new
                            Starfish profile!
                        </p> */}
                        <div className='flex animate-bounce mt-3 flex-row items-center mx-auto md:hidden'>
                            <p className='text-blue-500 font-semibold'>
                                Scroll down to sign up!
                            </p>
                            <SvgChevronDown className={'text-blue-500'}/>
                        </div>
                    </div>
                }
                <form
                    className='flex flex-col bg-white border border-slate-200 z-[2] shadow-2xl  p-4 md:p-4  rounded-2xl mb-5 w-[95vw] md:w-[40vw]'
                    onSubmit={e => e.preventDefault()}>
                    <div className="flex flex-col w-full gap-3">
                        <div className={`flex flex-col gap-0 mb-5`}>
                            <div className='title1 mb-1'>
                                Create Your Starfish Account
                            </div>
                            <div className='body2 text-neutral-400'>
                                Sign up to get started!
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            {/* <div className='flex-row flex gap-2 flex-1'>
                                <TextInput
                                    label={"First Name"}
                                    name="firstName"
                                    onChange={(e) => this.onChangeFirstName(e.target.value)}
                                    value={this.state.firstName}
                                    placeholder="First Name"
                                    className={'flex-1 flex flex-col w-12'}
                                />
                                <TextInput
                                    label={"Last Name"}
                                    name="lastName"
                                    onChange={(e) => this.onChangeLastName(e.target.value)}
                                    value={this.state.lastName}
                                    placeholder="Last Name"
                                    className={'flex-1 flex flex-col w-12'}
                                />
                            </div>
                            <TextInput
                                label={'Username'}
                                value={this.state.username}
                                onChange={(e) => this.onChangeUsername(e.target.value)}
                                placeholder={'Enter username'}
                            /> */}
                            <TextInput
                                label={'Email'}
                                value={this.state.email}
                                onChange={(e) => this.onChangeEmail(e.target.value)}
                                placeholder={'Enter email'}
                                type="email"
                            />
                            <TextInput
                                label={'Password'}
                                value={this.state.password}
                                onChange={this.passwordChanged}
                                placeholder={'•••••••••••••'}
                                type="password"
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='text-center mb-2'>
                                <p className="text-red-500 text-xs" style={{minHeight: 18, maxWidth: 300}}>
                                    {this.state.error}
                                </p>
                            </div>
                            <Button variant='default' onClick={this.signUpPressed}>
                                {this.state.loading ? <Spinner/> : 'Sign up with email'}
                            </Button>
                            <Button variant='secondary' onClick={this.signUpGoog} className='mt-12'>
                                <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" className="w-5 h-5 mr-2" />
                                Sign up with Google
                            </Button>
                            <div className='flex flex-row text-sm items-center justify-center gap-2 mt-3'>
                                <p className='text-slate-500'>
                                    Already have an account?
                                </p>
                                <p
                                    onClick={this.props.toggleTab}
                                    className='text-blue-500 font-semibold hover:text-blue-700 cursor-pointer'>
                                    Log In
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default SignUpCard;
