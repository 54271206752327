import React, {Component} from "react";
import {connect} from "react-redux";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import ProfileWorkItem from "./ProfileWorkItem";
import ProfileSectionHeader from "./ProfileSectionHeader";
import AddWorkModal from "../modals/AddWorkModal";
import { deleteConnection } from "../../api/users";
import SvgSupporter from "../icons/SvgSupporter";
import SvgVolunteer from "../icons/SvgVolunteer";
import SvgAward from "../icons/SvgAward";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutWork extends Component {
    state = {
        addWorkModalOpen: false,
        isEditingExistingItems: false,
        currentWorkItem: {},
    }

    openAddWorkModal = (currentWorkItem={}) => {
        this.setState({addWorkModalOpen: true, currentWorkItem})
    }

    closeAddWorkModal = () => {
        this.setState({addWorkModalOpen: false})
    }

    toggleEditExistingMode = () => {
        this.setState({isEditingExistingItems: !this.state.isEditingExistingItems})
    }

    deleteConnection = async (ein) => {
        await deleteConnection(this.props.authUser, ein);
        window.location.reload();
    }

    selectColor = (role) => {
        switch (role) {
            case 'Supporter':
                return '#3b81f6'
            case 'Volunteer':
                return "#ea66c3"
            case 'Employee':
                return "#F17300"
        }
    }

    renderIcon = (role) => {
        switch (role) {
            case 'Supporter':
                return <SvgSupporter className={'w-5 h-5'}/>
            case 'Volunteer':
                return <SvgVolunteer className={'w-5 h-5'}/>
            case 'Employee':
                return <SvgAward className={'w-5 h-5'}/>
        }
    }

    render() {
        const work = this.props.work;
        const user = this.props.user;
        const prefix = this.props.sameUser ? "My" : `${user.first_name}'s`;
        work.sort((a, b) => {
            // First, compare roles
            const roleComparison = a.role.localeCompare(b.role);
            if (roleComparison !== 0) {
                return roleComparison;
            }
        
            // Check if start_date exists in both objects
            if (!b.date && !a.date) {
                return 0; // Both are missing start_date, considered equal
            } else if (!b.date) {
                return 1; // Place items without start_date at the end
            } else if (!a.date) {
                return -1; // Place items without start_date at the end
            }
            // If both have start_date, compare them
            return b.date.localeCompare(a.date);
        });

        let lastRole = null;
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Involvement`}
                    sameUser={this.props.sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddWorkModal}
                />
                {work.length === 0 ? (
                    <ZeroStateArea
                        title={'Nothing here...'}
                        sub={`${user.first_name} does not have any involvement listed yet.`}
                    />
                ) : (
                    work.map(item => {
                        const showRoleHeader = item.role !== lastRole;
                        lastRole = item.role;
                        return (
                            <React.Fragment key={item.ein}>
                                {showRoleHeader && (
                                    <div
                                        style={{color: this.selectColor(item.role), backgroundColor: this.selectColor(item.role) + '20'}}
                                        className='flex flex-row items-center gap-3 rounded-xl font-medium py-2 px-3 mt-3'>
                                        {this.renderIcon(item.role)}
                                        <h3 className="text-lg">{item.role} Roles</h3>
                                    </div>
                                )}
                                <ProfileWorkItem
                                    item={item}
                                    onClickEdit={this.openAddWorkModal}
                                    onClickDelete={this.deleteConnection}
                                    editing={this.state.isEditingExistingItems}
                                />
                            </React.Fragment>
                        );
                    })
                )}
                <AddWorkModal
                    authUser={this.props.authUser}
                    item={this.state.currentWorkItem}
                    isOpen={this.state.addWorkModalOpen}
                    close={this.closeAddWorkModal}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(ProfileAboutWork)
