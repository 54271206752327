import React, {useState} from 'react';
import TextInput from "../../components/inputs/TextInput";
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";

function OnboardingBio({goNextStep, goBackStep, bio, setBio, firstName, lastName}) {
    const [errorMessage, setErrorMessage] = useState('');

    const handleBioChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setBio(event.target.value);
    };

    const validateForm = () => {
        if (!bio) {
            setErrorMessage('Please enter a bio.');
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            goNextStep();
        }
    };

    return (
        <div className='flex flex-col gap-8 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Add a mission to your profile'}
                description={'Tell the world a little more about you'}
            />
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row items-center gap-3'>
                    <div className='w-14 h-14 rounded-full bg-slate-200 flex flex-col items-center justify-center text-2xl text-slate-800 font-semibold'>
                        {firstName[0]}{lastName[0]}
                    </div>
                    <p className={'text-lg text-slate-800 font-semibold'}>
                        {firstName} {lastName}
                    </p>
                </div>
                <TextInput
                    label={'Your mission'}
                    value={bio}
                    placeholder={'ie. Student - Univ. of Michigan. I care about my school, helping animals get adopted, my faith, keeping the environment clean, and healthcare for children.'}
                    onChange={handleBioChange}
                    textarea
                    className={'min-h-[120px]'}
                />
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!bio}
            />
        </div>
    );
}

export default OnboardingBio;
