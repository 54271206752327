import React, {PureComponent} from "react";
import {motion} from "framer-motion";
import SvgApple from "../../components/icons/SvgApple";
import SvgGooglePlay from "../../components/icons/SvgGooglePlay";
import SvgMegaphone from "../../components/icons/SvgMegaphone";
import {withRouter} from "react-router-dom";


class DownloadAppCTA extends PureComponent {
    state = {}


    render() {
        return (
            <div className={`col-ac`}
                 style={{
                     padding: this.props.mobile ? '80px 24px' : '1vw 10vw 1vw 10vw'
                 }}>
                <motion.div style={{maxWidth: !this.props.mobile && '50vw', border: '2px solid #054A91'}}
                            initial={{opacity: 0, transform: 'scale(1) translateY(12px)'}}
                            whileInView={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            transition={{default: {ease: "linear"}, delay: .2}}
                            className={this.props.mobile ? 'mb-40 col-ac text-center p-5 br-24' : 'col-ac text-center  p-10 br-24'}>

                    <div className='row-ac primary mb-4'
                         style={{border: '1px solid #00000010', padding: '4px 20px', borderRadius: 100}}>
                        <div
                            className={`nav-icon  mt-4`}>
                            <SvgMegaphone width={20} height={20} className={''}/>
                        </div>
                        <p className='body2 ml-8'>
                            {this.props.ctaText}
                        </p>
                    </div>
                    <h1 className={`${this.props.mobile ? 'heading4' : 'heading3'}  mb-12 mt-0 text-center primary`}>
                        Download our app!
                    </h1>
                    <p className='mt-0 body1 black-70'>
                        Create your profile, make an impact, and share your stories of good today!
                    </p>
                    <div className="flex flex-row row-ac">
                            <a style={{textDecoration: 'none', marginRight: 8}}
                            href='https://apps.apple.com/us/app/starfish-social/id1642351040' target={'_blank'}
                            className='primary-button bg-white row-ac body2-bold mt-12'>
                                <SvgApple width={24} height={24}/>
                                <div className='ml-8' style={{marginTop: 2}}>
                                    iOS
                                </div>
                            </a>
                            <a style={{textDecoration: 'none'}}
                                href='https://play.google.com/store/apps/details?id=com.starfishsocialmedia.app&hl=en_US&gl=US'
                                target={'_blank'}
                                className='primary-button bg-white row-ac body2-bold mt-12'>
                                <SvgGooglePlay className={'mr-8'} width={24} height={24}/>
                                    Android
                            </a>
                        </div>
                </motion.div>
            </div>
        )
    }
}

export default withRouter(DownloadAppCTA)
