import './App.css';
import './styles/Formatting.css';
import './styles/ColorStyles.css'
import './styles/Inputs.css'
import './styles/Navbar.css'
import './styles/TextStyles.css'
import './styles/ButtonStyles.css'
import './styles/HeroStyles.css'

import React, {PureComponent} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import UserProvider, {UserContext} from "./providers/UserProvider";
import {Provider} from "react-redux";
import Store from "./Store";
import Routes from './Routes';
import { datadogLogs } from '@datadog/browser-logs';

const DD_CLIENT_TOKEN = "pub0761b063cc0a4bcdc3dd7d87dbd254c0";
datadogLogs.init({
    // applicationId: DD_APP_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    service: 'starfish_web',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    // env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Connect to backend traces
    allowedTracingUrls: ["https://starfish-seethegood.uc.r.appspot.com", "https://dev-dot-starfish-seethegood.uc.r.appspot.com"],
});
datadogLogs.logger.setLevel('info');

class App extends PureComponent {
    render() {
        return (
            <UserProvider>
                <Router>
                    <Provider store={Store}>
                        <UserContext.Consumer>
                            {(user) => (
                                <Routes user={user}/>
                            )}
                        </UserContext.Consumer>
                    </Provider>
                </Router>
            </UserProvider>
        )
    }
}

export default App;
