import React, {Component} from "react";
import AuthModal from "../components/AuthModal";
import DonateModal from "../components/DonateModal";
import {withRouter} from "react-router-dom";
import {wait} from "../utils/timestamp";
import {getNonProfit} from "../api/nonProfits";
import {getNonProfitChallenges} from "../api/challenges";
import ChallengeCard from "../components/ChallengeCard";
import SvgPin from "../components/icons/SvgPin";
import SvgMoney from "../components/icons/SvgMoney";
import SvgLink from "../components/icons/SvgLink";
import SvgCheck from "../components/icons/SvgCheck";
import SvgShare from "../components/icons/SvgShare";
import {createDonationCheckout} from "../api/stripe_connect";
import {connect} from "react-redux";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {CARD_STYLE_NO_HOVER, NPO_CATEGORY_COLOR_DICT, NPO_CATEGORY_ICON_DICT, reverseCategoryMap} from "../utils/dummy";
import {Button} from "../components/atoms/Button";
import SectionTabs from "../components/SectionTabs";
import {getNonProfitMentions} from "../api/feeds";
import PostComponent from "../components/posts/PostComponent";
import SvgArrowForward from "../components/icons/ArrowForward";
import NpoInfo from "../components/NpoInfo";
import ZeroStateArea from "../components/atoms/ZeroStateArea";
import NPOPicture from "../components/atoms/NPOPicture";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
        interests: state.userReducer.interests,
        posts: state.postReducer.posts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
        removeInterest: (interest) => {
            dispatch({
                type: "REMOVE_INTEREST",
                interest,
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
    }
}


class NonProfitPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            nonProfit: {},
            copied: false,
            authModalOpen: false,
            donateModalOpen: false,
            amount: 0,
            loading: true,
            memo: '',
            challenges: [],
            posts: [],
            activeTab: 'Posts',
            copiedLink: false,
        }
    }

    getNpInfo = async () => {
        this.setState({loading: true});
        const user = this.props.user;

        if (!user) {
            this.setState({authModalOpen: true, loading: false});
            return;
        }

        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const ein = lastArg.split("?")[0];

        const res = await getNonProfit(this.props.authUser, ein);
        const challenges = await getNonProfitChallenges(this.props.authUser, user.username, ein);
        const posts = await getNonProfitMentions(this.props.authUser, ein);

        const admins = res.non_profit?.admins || {};
        const claimed = Object.keys(admins).length > 0;

        this.props.updatePosts(posts.mentions);
        this.setState({
            loading: false,
            nonProfit: res.non_profit,
            challenges: challenges.challenges,
            posts: posts.mentions,
            claimed
        });
    }

    componentDidMount = () => {
        this.getNpInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.user !== prevProps.user) {
            this.getNpInfo();
        }
    }

    createCheckout = async () => {
        console.log("CREATING DONATION CHECKOUT")
        createDonationCheckout(this.props.authUser, this.state.amount, this.state.nonProfit.ein, false, null, this.state.memo);
    }

    openAuthModal = () => {
        if (!!this.props.user) {
            this.createCheckout();
        } else {
            this.setState({authModalOpen: true});
        }
    }

    closeAuthModal = () => {
        this.setState({authModalOpen: false})
    }

    openDonateModal = () => {
        if (!!this.props.user) {
            this.setState({donateModalOpen: true})
        } else {
            this.setState({authModalOpen: true})
        }
    }

    closeDonateModal = () => {
        this.setState({donateModalOpen: false})
    }

    updateAmount = (amount) => {
        this.setState({amount: amount})
    }

    onChangeMemo = (memo) => {
        this.setState({memo});
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    copyLinkToClipboard = (e) => {
        e.stopPropagation()
        this.setState({copiedLink: true});
        const nonProfit = this.state.nonProfit || {};
        const link = `https://share.starfishapp.com/non-profit/${nonProfit.ein}`
        navigator.clipboard.writeText(link)
        wait(3000).then(() => {
            this.setState({copiedLink: false})
        })
    }


    render() {
        const nonProfit = this.state.nonProfit || {};
        let previous = "";
        if (!!this.props.location.state) {
            previous = this.props.location.state.previous;
        }
        let url = nonProfit.website_url;
        if (!!url && !url.includes("http")) {
            url = "http://" + url;
        }
        if (!this.props.user) {
            return (
                <div className='col-ac-jc' style={{width: '100vw', height: '100vh'}}>
                    <p className='black-50 mb-32'>
                        Please log in to view this nonprofit.
                    </p>
                    <button onClick={() => this.setState({authModalOpen: true})}
                            className={`body1-bold primary-button  ${this.props.mobile && 'button-sm'} `}>
                        Log In
                    </button>
                    <AuthModal
                        isOpen={this.state.authModalOpen}
                        closeModal={this.closeAuthModal}
                        mobile={this.props.mobile}
                        createCheckout={this.createCheckout}
                    />
                </div>
            )
        }
        const category = reverseCategoryMap[nonProfit.code];
        const SvgIcon = NPO_CATEGORY_ICON_DICT[category];
        const svgColor = NPO_CATEGORY_COLOR_DICT[category];
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    back
                    previous={previous}
                />
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                        <div className={`${CARD_STYLE_NO_HOVER}`}>
                            <img src={nonProfit.cover_photo}
                                 style={{
                                     width: '100%',
                                     height: this.props.mobile ? 100 : 160,
                                     objectFit: 'cover',
                                     border: '1px solid #00000020'
                                 }}
                                 className='br-8'
                                 onError={(e) => {
                                     e.target.src =
                                         require('../img/blank_cover.jpg');
                                     this.setState({imageError: true});
                                 }}
                            />
                            <div style={{marginTop: -100, marginLeft: 12}}>
                                <NPOPicture
                                    size={120}
                                    np={nonProfit}
                                    hideCategory
                                    className={'border-2 bg-white border-white mr-8'}
                                />
                            </div>
                            <div className='row-jb mt-12'>
                                <div className='flex flex-col gap-1'>
                                    <p className='text-2xl text-slate-800 font-semibold'>
                                        {nonProfit.name}
                                    </p>
                                    <div className='flex flex-row items-center gap-2'>
                                        {SvgIcon &&
                                            <div className='rounded-full' style={{
                                                padding: 2,
                                                backgroundColor: svgColor,
                                                borderColor: "white",
                                                borderWidth: 1,
                                            }}>
                                                <SvgIcon className={'text-white'} width={24} height={24} />
                                            </div>
                                        }
                                        <p className='text-md font-medium text-slate-500'>
                                            {reverseCategoryMap[nonProfit.code]}
                                        </p>
                                    </div>
                                    {/*<p className='text-md font-medium text-slate-500'>*/}
                                    {/*    {nonProfit.category}*/}
                                    {/*</p>*/}
                                </div>
                                <div className='flex flex-row items-center gap-3'>
                                    {this.props.interests.some(interest => interest.id === nonProfit.ein) ?
                                        <Button
                                            onClick={() => {
                                                this.props.removeInterest({id: nonProfit.ein, name: nonProfit.name})
                                            }}
                                            variant={'default'}
                                            radius={'full'}
                                        >
                                            Unfollow
                                        </Button>
                                        :
                                        <Button
                                            onClick={() => {
                                                this.props.addInterest({id: nonProfit.ein, name: nonProfit.name})
                                            }}
                                            variant={'default'}
                                            radius={'full'}
                                        >
                                            Follow
                                        </Button>
                                    }
                                    <Button
                                        onClick={this.openDonateModal}
                                        variant={'default'}
                                        radius={'full'}
                                    >
                                        <SvgMoney className={'w-5 h-5'}/> Donate
                                    </Button>
                                </div>
                            </div>
                            <div className='mt-1 flex flex-row items-center gap-3'>
                                <div className='row-ac'>
                                    <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                    <p className='text-md text-blue-500 ml-4'>
                                        {nonProfit.city}, {nonProfit.state}
                                    </p>
                                </div>
                                {!this.state.claimed ?
                                    <a className='row-ac' href={"https://nonprofit.starfishapp.com/claim"}
                                       target={"_blank"}>
                                        <SvgArrowForward className={'text-blue-500 w-3 h-3'}/>
                                        <p className='text-md text-blue-500 ml-4'>
                                            Claim this profile
                                        </p>
                                    </a>
                                    : null}
                            </div>
                            <p className='text-md text-slate-700 my-5'>
                                {nonProfit.mission}
                            </p>
                            <div className='flex flex-row gap-5 wrap'>
                                {!!nonProfit.num_employees ?
                                    <p className='text-md text-slate-700'>
                                        <span className='font-semibold'>{nonProfit.num_employees}</span> employees
                                    </p>
                                    : null}
                                {!!nonProfit.ruling_year ?
                                    <p className='text-md text-slate-700'>
                                        Since <span className='font-semibold'>{nonProfit.ruling_year}</span>
                                    </p>
                                    : null}
                                {!!nonProfit.ein ?
                                    <p className='text-md text-slate-700'>
                                        EIN: <span className='font-semibold'>{nonProfit.ein}</span>
                                    </p>
                                    : null}
                                {!!nonProfit.website_url ?
                                    <div className='row-ac text-slate-700 hover:text-blue-500 cursor-pointer'
                                         style={{cursor: 'pointer'}}>
                                        <SvgLink style={{width: 20, height: 20,}} className={''}/>
                                        <a className='text-md font-semibold ml-2' style={{textDecoration: "none"}}
                                           target='_blank'
                                           rel='noopener noreferrer' href={url}>
                                            Website
                                        </a>
                                    </div>
                                    : null}
                                <div className="flex items-center gap-3">
                                    <div onClick={this.copyLinkToClipboard}
                                        className={`flex flex-row items-center gap-1  cursor-pointer ${this.state.copiedLink && 'text-blue-500'}`}>
                                        {this.state.copiedLink ?
                                            <SvgCheck className={'w-4 h-4 scale-[1.2]'}/>
                                            :
                                            <SvgShare className={'w-4 h-4 scale-[1.2]'}/>
                                        }
                                        <p className='text-sm'>
                                            {this.state.copiedLink ?
                                                'Link copied!' :
                                                'Share profile'
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <SectionTabs
                                activeTab={this.state.activeTab}
                                changeTab={this.changeTab}
                                tabs={['Posts', 'Challenges', 'Info']}
                            />
                        </div>
                        {this.state.activeTab === 'Challenges' &&
                            <>
                                {this.state.challenges.map(challenge => {
                                        return (
                                            <ChallengeCard inContainer challenge={challenge} mobile={this.props.mobile}/>
                                        )
                                    }
                                )}
                                {this.state.challenges.length === 0 ?
                                    <ZeroStateArea
                                        title={'Nothing here...'}
                                        sub={`${nonProfit.name} has not had any challenges yet. Download the Starfish app to start one on their behalf!`}
                                    />
                                    : null
                                }
                            </>
                        }
                        {this.state.activeTab === 'Posts' &&
                            <>
                                {this.state.posts.map(post => {
                                        return (
                                            <PostComponent
                                                post={this.props.posts[post.post_ID]}
                                            />
                                        )
                                    }
                                )}
                                {this.state.posts.length === 0 ?
                                    <ZeroStateArea
                                        title={'Nothing here...'}
                                        sub={`${nonProfit.name} has not made any posts yet. Tag them in a post to get them started!`}
                                    />
                                    : null
                                }
                            </>
                        }
                        {this.state.activeTab === 'Info' &&
                            <>
                                <NpoInfo nonProfit={nonProfit}/>
                            </>
                        }
                    </div>
                }
                <AuthModal
                    isOpen={this.state.authModalOpen}
                    closeModal={this.closeAuthModal}
                    mobile={this.props.mobile}
                    createCheckout={this.createCheckout}
                />
                <DonateModal
                    isOpen={this.state.donateModalOpen}
                    closeModal={this.closeDonateModal}
                    openAuthModal={this.openAuthModal}
                    mobile={this.props.mobile}
                    nonProfit={nonProfit}
                    updateAmount={this.updateAmount}
                    memo={this.state.memo}
                    onChangeMemo={this.onChangeMemo}
                />
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NonProfitPage));
