import React, {Component} from "react";
import {connect} from "react-redux";
import {CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import ZeroStateArea from "../atoms/ZeroStateArea";
import ProfileEducationItem from "./ProfileEducationItem";
import { deleteEducation } from "../../api/users";
import AddEducationModal from "../modals/AddEducationModal";
import ProfileSectionHeader from "./ProfileSectionHeader";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}

class ProfileAboutEducation extends Component {
    state = {
        addEducationModalOpen: false,
        isEditingExistingItems: false,
        currentEducationItem: {},
    }

    openAddEducationModal = (currentEducationItem={}) => {
        this.setState({addEducationModalOpen: true, currentEducationItem})
    }

    closeAddEducationModal = () => {
        this.setState({addEducationModalOpen: false})
    }

    toggleEditExistingMode = () => {
        this.setState({isEditingExistingItems: !this.state.isEditingExistingItems})
    }

    deleteEducation = async (domain) => {
        await deleteEducation(this.props.authUser, domain);
        window.location.reload();
    }

    render() {
        const education = this.props.education
        const user = this.props.user
        const prefix = this.props.sameUser ? "My" : `${user.first_name}'s`;
        education.sort((a, b) => {
            // Check if start_date exists in both objects
            if (!b.start_date && !a.start_date) {
                return 0; // Both are missing start_date, considered equal
            } else if (!b.start_date) {
                return 1; // Place items without start_date at the end
            } else if (!a.start_date) {
                return -1; // Place items without start_date at the end
            }
            // If both have start_date, compare them
            return b.start_date.localeCompare(a.start_date);
        });
        return (
            <div className={`${CARD_STYLE_NO_HOVER}`}>
                <ProfileSectionHeader
                    label={`${prefix} Education`}
                    sameUser={this.props.sameUser}
                    editing={this.state.isEditingExistingItems}
                    setEditingMode={this.toggleEditExistingMode}
                    onClickAdd={this.openAddEducationModal}
                />
                {education.map((item, index) => {
                        return (
                            <ProfileEducationItem
                                item={item}
                                index={index}
                                onClickEdit={this.openAddEducationModal}
                                onClickDelete={this.deleteEducation}
                                editing={this.state.isEditingExistingItems}
                            />
                        )
                    }
                )}
                {education.length === 0 ?
                    <ZeroStateArea
                        title={'Nothing here...'}
                        sub={`${user.first_name} does not have any education listed yet.`}
                    />
                    :
                    null
                }
                <AddEducationModal
                    authUser={this.props.authUser}
                    isOpen={this.state.addEducationModalOpen}
                    close={this.closeAddEducationModal}
                    item={this.state.currentEducationItem}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps)(ProfileAboutEducation)