import React, {Component} from "react";
import ProfileAboutPurpose from "./ProfileAboutPurpose";
import ProfileAboutWork from "./ProfileAboutWork";
import ProfileAboutEducation from "./ProfileAboutEducation";

class ProfileAboutSection extends Component {

    render() {
        const user = this.props.user;
        const education = user.education || [];
        return (
            <div>
                <ProfileAboutPurpose
                    user={user}
                    sameUser={this.props.sameUser}
                />
                <ProfileAboutWork
                    work={this.props.connections}
                    sameUser={this.props.sameUser}
                    user={user}
                />
                <ProfileAboutEducation
                    education={education}
                    sameUser={this.props.sameUser}
                    user={user}
                />
            </div>
        )
    }
}

export default ProfileAboutSection