import axios from "./axios";

export const getUserPosts = async (user, user_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id: user_id}
        }; 
        const res = await axios.get("/feeds/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getGroupPosts = async (user, group_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {group_id}
        }; 
        const res = await axios.get("/feeds/group", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getFollowerPosts = async (user, index=null) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {index}
        };
        const res = await axios.get("/feeds/user/following", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getForYouPosts = async (user, index=null) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {index}
        };
        const res = await axios.get("/feeds/user/for-you", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getNonProfitMentions = async (user, ein) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {ein}
        };
        const res = await axios.get("/feeds/non_profits/mentions", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getHashTagPosts = async (user, hashtag) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {hashtag}
        };
        const res = await axios.get("/feeds/hashtag", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}